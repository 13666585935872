import React, { useRef, useState } from "react";
import "./ForgetPassword.scss";
import { Modal, Form, Input, Typography, message } from "antd";
import Utils from "../../utils";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../features/forgetPassword/resetPasswordActions";

export default function ForgetPassword({
  isOpened,
  title,
  onCancel,
  openLogedInModal,
}) {
  const formRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const openSuccess = (type, text) => {
    messageApi.open({
      type: type,
      content: text,
    });
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const onOk = (values) => {
    setLoading(true);
    dispatch(forgotPassword(values))?.then((res) => {
      if (res.type === "password_forgot/fulfilled") {
        formRef?.current?.resetFields();
        setLoading(false);
        setError(null);
        onCancel();
        openSuccess(
          "success",
          res?.payload?.msg ? res.payload.msg : "email sent"
        );
      } else if (res.type === "password_forgot/rejected") {
        formRef?.current?.setFields([
          {
            name: "email", // Field name you want to set the error for
            errors: [res?.payload?.msg], // Assuming your error has a 'message' field
          },
        ]);
        setLoading(false);
        setError(res?.payload);
      }
    });
  };
  return (
    <>
      {contextHolder}
      <Modal
        className="forgetPasswordModal-cont"
        classNames={{
          footer: "forgetPasswordModal-cont__footer",
        }}
        open={isOpened}
        title={title}
        centered={true}
        okText="Send to email"
        cancelText="Back"
        onCancel={() => {
          onCancel();
          formRef?.current?.resetFields();
          setLoading(false);
          if (error) {
            setError(null);
          }
        }}
        onOk={() => {
          formRef?.current?.submit();
        }}
        cancelButtonProps={{
          className: "forgetPasswordModal-cont__btn",
          size: "large",
          onClick: () => {
            onCancel();
            formRef?.current?.resetFields();
            setLoading(false);
            if (error) {
              setError(null);
            }
            openLogedInModal(true);
          },
        }}
        okButtonProps={{
          className: "forgetPasswordModal-cont__btn",
          htmlType: "submit",
          type: "primary",
          key: "submit",
          size: "large",
          loading: loading,
          disabled: error ? Object.keys(error)?.length !== 0 : false,
        }}>
        <Form
          className="forgetPasswordModal-cont__form"
          ref={formRef}
          layout="vertical"
          onFinish={(values) => {
            onOk(values);
          }}
          onFinishFailed={(error) => {
            console.log("onFinishFailed", error);
          }}>
          <Form.Item
            className="forgetPasswordModal-cont__form__formItem"
            name="email"
            rules={[
              {
                required: true,
                message: "",
              },
              {
                validator: Utils?.emailValidator,
              },
            ]}
            label={
              <Typography.Text className="forgetPasswordModal-cont__form__formItem__text">
                Email
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Email"
              size="large"
              onChange={() => {
                if (error) {
                  setError(null);
                }
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
